@import './font.scss';

*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --color-white: #fff;
    --color-black: #000;
    --color-blue: #3185FC;
    --color-gray: #373A49;
    --color-gray-light: #959598;
    --color-base-bg-light: #fff;
    --color-base-bg-dark: #283046;
    --color-base-text-light: #2F2F2F;
    --color-base-primary-light: #D9272D;
    --font-extrasmall: 9px;
    --font-small: 12px;
    --font-normal: 14px;
    --font-large: 16px;
    --font-extralarge: 26px;
    --font-bold: 600;
    --font-extrabold: 900;
}

body {
    padding: 0;
    margin: 0;
    background: var(--color-base-bg-light);
    font-family: 'Averta', sans-serif;
    font-size: var(--font-normal);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &[dark-mode] {
        background: var(--color-base-bg-dark);
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#dashboard-main {
    position: relative;
    display: block;
    width: 100%;
    max-width: calc(100% - 20%);
    padding-top: 60px;
    height: 100%;
    float: right;
    transition: max-width .2s ease;

    &.drawer-minimize {
        max-width: calc(100% - 7%);
    }

    @media only screen and (max-width: 768px) {
        max-width: none;
        float: none;
    }
}

.font-bold {
    font-weight: var(--font-bold);
}

.font-extrasmall {
    font-size: var(--font-extrasmall);
}

.font-small {
    font-size: var(--font-small);
}

.font-large {
    font-size: var(--font-large);
}

.font-extralarge {
    font-size: var(--font-extralarge);
}

.color-blue {
    color: var(--color-blue) !important;
}

.color-cs-primary {
    color: var(--color-base-primary-light) !important;
}

.color-gray-primary {
    color: var(--color-gray-light) !important;
}

#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    animation: fade-in .3s ease;
    z-index: 100;

    &.transparent {
        background: rgba(0, 0, 0, 0);
        backdrop-filter: none;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.loading-wrapper {
    position: fixed;
    display: flex;
    width: 160px;
    height: 160px;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: 0 0 10px 8px rgba(0, 0, 0, .05);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    z-index: 200;

    .loading-anim {
        position: relative;
        display: block;
        height: 100px;
        width: 100px;

        img {
            height: 100%;
        }
    }

    span {
        position: relative;
        display: block;
        width: 100%;
        margin-top: 10px;
        text-align: center;
        font-weight: var(--font-bold);
    }
}

.loading-spinner {
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::after {
        content: " ";
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
        margin: 10px;
        box-sizing: border-box;
        border: 42px solid var(--color-base-primary-light);
        border-color: var(--color-base-primary-light) transparent var(--color-base-primary-light) transparent;
        animation: loading-spinner 2s infinite;
    }
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
        transform: rotate(1800deg);
    }
}

.select-input {
    position: relative;

    &.active {
        color: #00840C;
    }
    &.non-active {
        color: #D9272D;
    }
}

.lines {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #EBE7E6;

    &.l-white {
        background: var(--color-white);
    }

    &.l-bold {
        height: 5px;
    }

    &.l-rounded {
        border-radius: 50px;
    }
}

.divider {
    position: relative;
    background: #f4f4f4;
    width: 100%;
    height: 12px;
}

.col-profile {
    position: relative;
    width: auto;

    .profile-img {
        position: relative;
        display: inline-block;
        height: 35px;
        width: 35px;

        img {
            position: relative;
            max-width: 100%;
            max-height: 100%;
            border-radius: 100px;
        }

        .more-profile {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .5);
            border-radius: 100px;
            align-items: center;
            justify-content: center;
            color: var(--color-white);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.stacking {
        display: flex;
        flex-direction: row;

        .profile-img {
            position: relative;
            width: auto;
            top: 0;
            left: 0;

            img {
                border: 2px solid #373A49;
            }
        }
    }
}

.mx-cs-2 {
    margin-left: 2.5em;
    margin-right: 2.5em;
}

.cursor-pointer:hover{
    cursor: pointer !important;
}

.rdt_Table {
    padding: 0 5px 120px;
    border: none;

    .rdt_TableHeadRow {
        background: #F7F5F5;
        border: 1px solid #D8CFCD;
        border-radius: 10px 10px 0 0;

        .rdt_TableCol {
            background: transparent;
            padding: 0 5px;

            &:first-child {
                padding-left: 1rem;
            }
        }

    }
}

.rdt_TableRow {
    border-left: 1px solid #D8CFCD;
    border-right: 1px solid #D8CFCD;
    border-bottom: 1px solid #D8CFCD;

    .rdt_TableCell {
        padding: 0 5px;

        &:first-child {
            padding-left: 1rem;
        }

    }

    &:last-child {
        border-radius: 0 0 10px 10px;
    }
}

.rdt_Pagination {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 10px 10px;
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #D9272D;
  --rdp-background-color: #FFE7DD;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #D9272D;
  --rdp-background-color-dark: #D9272D;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.rdp-months {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

// .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;

//     &::after {
//         content: '';
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         border-radius: 100%;
//         background: var(--rdp-accent-color-dark);
//         left: 0;
//         z-index: 1;
//     }
// }

// .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
//     background-color: var(--rdp-background-color-dark);
//     border-top-right-radius: 100%;
//     border-bottom-right-radius: 100%;

//     &::after {
//         content: '';
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         background: var(--rdp-accent-color);
//     }
// }